<template>
  <div>
    <b-row>
      <b-col
        v-if="!isPdv"
        md="4"
      >
        <e-store-combo
          v-model="filters.storeId"
          :required="false"
        />
      </b-col>
      <b-col md="4">
        <e-person-search
          id="supplier-id"
          v-model="filters.supplierId"
          :label="$t('Fornecedor')"
          is-supplier
          :only-active="false"
        />
      </b-col>
      <b-col
        v-if="!isPdv"
        md="4"
      >
        <e-person-search
          id="customer-id"
          v-model="filters.customerId"
          :label="$t('Cliente')"
          is-customer
          :only-active="false"
        />
      </b-col>
      <b-col md="4">
        <e-management-account
          v-model="filters.managementAccountId"
          :placeholder="$t('Todas')"
          :only-active="false"
          type="Output"
          name="filterManagementAccount"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="purchase_order_id"
          v-model="filters.purchaseOrderOrSaleId"
          :label="$t('Id do Pedido/Venda')"
          type="text-number"
          :precision="0"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="bill-document-type"
          v-model="filters.documentType"
          :label="$t('Tipo do documento')"
          type="vue-select"
          :options="documentTypeOptions()"
        />
      </b-col>
      <b-col md="2">
        <e-only-number-input
          id="purchase_document"
          v-model="filters.document"
          :label="$t('Nº do documento')"
          :required="false"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="value"
          v-model="filters.value"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Valor')"
        />
      </b-col>

      <b-col md="2">
        <FormulateInput
          id="expense-payment_status"
          v-model="filters.paymentStatus"
          :label="$t('Status')"
          type="vue-select"
          :placeholder="$t('Todos')"
          :options="[
            { value: 'isPaid', label: $t('Pago') },
            { value: 'isPending', label: $t('Pendente') },
            { value: 'isLate', label: $t('Em atraso') },
          ]"
        />
      </b-col>
      <b-col md="3">
        <e-payment-method
          id="expense-payment_method"
          v-model="filters.paymentMethodId"
          :placeholder="$t('Todos')"
        />
      </b-col>
      <b-col md="7">
        <FormulateInput
          id="value"
          v-model="filters.description"
          type="text"
          :label="$t('Descrição')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="expense-period_type"
          v-model="filters.periodType"
          type="vue-select"
          :label="$t('Tipo do período')"
          :options="payablePeriodTypeOptions()"
          :clearable="false"
        />
      </b-col>
      <b-col md="4">
        <FormulateInput
          id="expense-period"
          v-model="filters.period"
          type="date-range-picker"
          :label="$t('Período')"
          :class="periodIsRequired ? 'required' : ''"
          :validation="periodIsRequired ? 'requiredrange' : ''"
          :validation-messages="{
            requiredrange: $t('Informe um período'),
          }"
          :time-picker="false"
          :instruction="periodInstruction"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { EStoreCombo, EPersonSearch, EPaymentMethod, EManagementAccount } from '@/views/components'
import { financialDomain } from '@/mixins'
import EOnlyNumberInput from '@/views/components/inputs/EOnlyNumberInput.vue'

export default {
  components: {
    BRow,
    BCol,
    EStoreCombo,
    EPersonSearch,
    EPaymentMethod,
    EManagementAccount,
    EOnlyNumberInput,
  },

  mixins: [financialDomain],

  props: {
    filters: {
      type: Object,
      required: true,
    },

    isPdv: {
      type: Boolean,
      default: false,
    },

    periodIsRequired: {
      type: Boolean,
      default: false,
    },

    periodInstruction: {
      type: String,
      default: '',
    },
  },

  computed: {},

  methods: {
    // ...mapActions('app', ['fetchCheckingAccounts']),
    // ...mapActions('common/managementAccounts', ['fetchManagementAccounts']),
  },
}
</script>

<style lang="scss" scoped></style>
