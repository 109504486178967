var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          !_vm.isPdv
            ? _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-store-combo", {
                    attrs: { required: false },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("e-person-search", {
                attrs: {
                  id: "supplier-id",
                  label: _vm.$t("Fornecedor"),
                  "is-supplier": "",
                  "only-active": false,
                },
                model: {
                  value: _vm.filters.supplierId,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "supplierId", $$v)
                  },
                  expression: "filters.supplierId",
                },
              }),
            ],
            1
          ),
          !_vm.isPdv
            ? _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-person-search", {
                    attrs: {
                      id: "customer-id",
                      label: _vm.$t("Cliente"),
                      "is-customer": "",
                      "only-active": false,
                    },
                    model: {
                      value: _vm.filters.customerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "customerId", $$v)
                      },
                      expression: "filters.customerId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("e-management-account", {
                attrs: {
                  placeholder: _vm.$t("Todas"),
                  "only-active": false,
                  type: "Output",
                  name: "filterManagementAccount",
                },
                model: {
                  value: _vm.filters.managementAccountId,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "managementAccountId", $$v)
                  },
                  expression: "filters.managementAccountId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "purchase_order_id",
                  label: _vm.$t("Id do Pedido/Venda"),
                  type: "text-number",
                  precision: 0,
                },
                model: {
                  value: _vm.filters.purchaseOrderOrSaleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "purchaseOrderOrSaleId", $$v)
                  },
                  expression: "filters.purchaseOrderOrSaleId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "bill-document-type",
                  label: _vm.$t("Tipo do documento"),
                  type: "vue-select",
                  options: _vm.documentTypeOptions(),
                },
                model: {
                  value: _vm.filters.documentType,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "documentType", $$v)
                  },
                  expression: "filters.documentType",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("e-only-number-input", {
                attrs: {
                  id: "purchase_document",
                  label: _vm.$t("Nº do documento"),
                  required: false,
                },
                model: {
                  value: _vm.filters.document,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "document", $$v)
                  },
                  expression: "filters.document",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "value",
                  type: "text-number",
                  currency: "R$",
                  precision: 2,
                  label: _vm.$t("Valor"),
                },
                model: {
                  value: _vm.filters.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "value", $$v)
                  },
                  expression: "filters.value",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "expense-payment_status",
                  label: _vm.$t("Status"),
                  type: "vue-select",
                  placeholder: _vm.$t("Todos"),
                  options: [
                    { value: "isPaid", label: _vm.$t("Pago") },
                    { value: "isPending", label: _vm.$t("Pendente") },
                    { value: "isLate", label: _vm.$t("Em atraso") },
                  ],
                },
                model: {
                  value: _vm.filters.paymentStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "paymentStatus", $$v)
                  },
                  expression: "filters.paymentStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("e-payment-method", {
                attrs: {
                  id: "expense-payment_method",
                  placeholder: _vm.$t("Todos"),
                },
                model: {
                  value: _vm.filters.paymentMethodId,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "paymentMethodId", $$v)
                  },
                  expression: "filters.paymentMethodId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "7" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "value",
                  type: "text",
                  label: _vm.$t("Descrição"),
                },
                model: {
                  value: _vm.filters.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "description", $$v)
                  },
                  expression: "filters.description",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "expense-period_type",
                  type: "vue-select",
                  label: _vm.$t("Tipo do período"),
                  options: _vm.payablePeriodTypeOptions(),
                  clearable: false,
                },
                model: {
                  value: _vm.filters.periodType,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "periodType", $$v)
                  },
                  expression: "filters.periodType",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                class: _vm.periodIsRequired ? "required" : "",
                attrs: {
                  id: "expense-period",
                  type: "date-range-picker",
                  label: _vm.$t("Período"),
                  validation: _vm.periodIsRequired ? "requiredrange" : "",
                  "validation-messages": {
                    requiredrange: _vm.$t("Informe um período"),
                  },
                  "time-picker": false,
                  instruction: _vm.periodInstruction,
                },
                model: {
                  value: _vm.filters.period,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "period", $$v)
                  },
                  expression: "filters.period",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }